$(function () {
    initNav();
    initSlick();
    initAanbodCarousel();
    initUspsCarousel();
    initDropdown();
    initMatchHeight();
    initObject();
    initScroll();
    initTimeline();
    initContact();
    initTimers();
    initSearch();
    initProducts();
    initBid();

    initFindPrice();

    $('[data-toggle="tooltip"]').tooltip();
});

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        var $nav = $('header nav');
        $nav.children('ul').attr('style', false);
        initObject();
    }, 200));
});

function initNav() {
    var $btn_navigation = $('header .nav-toggle');
    var $nav = $('header nav');

    $btn_navigation.click(function (e) {
        e.stopPropagation();
        e.preventDefault();

        if ($(window).width() < 980) {
            if ($nav.css('visibility') === 'hidden' || $nav.is(':hidden')) {
                $nav.slideDown();
                $('body').addClass('nav-open');
            } else {
                $nav.slideUp();
                $('body').removeClass('nav-open');
            }
        }
    });

    if ($nav.find('ul').length > 0) {
        $li = $nav.find('li');

        $li.has('ul').children('a').click(function (e) {

            $(this).closest('ul').children('li').find('ul').each(function (i, ul) {
                if ($(ul) != $(this).next('ul')) {
                    $(this).prev('a').children('span').removeClass('toggle');

                    if ($(window).width() < 1024) {
                        $(ul).slideUp();
                    }
                }
            });

            if ($(this).next('ul').css('visibility') === 'hidden' || $(this).next('ul').is(':hidden')) {
                e.preventDefault();

                if ($(window).width() < 1024) {
                    $(this).next('ul').slideDown();
                }
                $(this).children('span').addClass('toggle');
            }
        });
    }

    if ($(this).scrollTop() > $('header .topheader').outerHeight()) {
        $('.nav_wrapper').addClass('fixed');
    } else {
        $('.nav_wrapper').removeClass('fixed');
    }
    $(window).scroll(function () {
        if ($(this).scrollTop() > $('header .topheader').outerHeight()) {
            $('.nav_wrapper').addClass('fixed');
        } else {
            $('.nav_wrapper').removeClass('fixed');
        }
    });
}

function initSlick() {
    if ($('#header_carousel').length > 0) {
        $('#header_carousel').slick({
            dots: false,
            arrows: false,
            autoplay: true
        });
    }
}

function initAanbodCarousel() {
    if ($('#aanbod_carousel').length > 0) {
        $('#aanbod_carousel').slick({
            arrows: true,
            slidesToShow: 2,
            prevArrow: $('#aanbod_wrapper .icon-arrow-left'),
            nextArrow: $('#aanbod_wrapper .icon-arrow-right'),
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }

    if ($('#uitgelicht_carousel').length > 0) {
        $('#uitgelicht_carousel').slick({
            arrows: true,
            slidesToShow: 2,
            prevArrow: $('#uitgelicht_carousel .icon-arrow-left'),
            nextArrow: $('#uitgelicht_carousel .icon-arrow-right'),
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
}

function initUspsCarousel() {
    if ($('#usps').length > 0) {
        $('#usps').slick({
            arrows: false,
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
}

function initDropdown() {
    $('.dropdown').each(function (i, el) {
        $(el).on('click', function () {
            $(el).find('ul').fadeToggle(150);

            $(el).find('a').on('click', function (e) {
                $(el).find('.value').text($(this).text());
            });
        });
    });
}

function initMatchHeight() {
    if ($('.matchheight').length > 0) {
        $('.matchheight').matchHeight();
    }
}

function initObject() {
    if ($('#object').length > 0) {
        var top = Math.round($('#wrapper_omschrijving').position().top);
        var floater_top = top + 100;
        $(window).scroll(function () {
            clearTimeout($.data(this, 'scrollTimer'));

            newtop = Math.round((top - $(this).scrollTop()) * -1);
            maxtop = Math.round($('#wrapper_contact').position().top + $('#wrapper_contact').outerHeight() - $('.floater').outerHeight());

            if ($(this).scrollTop() > top) {
                if (maxtop > $(this).scrollTop()) {
                    $.data(this, 'scrollTimer', setTimeout(function () {
                        $('.floater').css('top', Math.min(newtop, (maxtop - floater_top)) + 30);
                    }, 30));
                } else {
                    $.data(this, 'scrollTimer', setTimeout(function () {
                        $('.floater').css('top', (maxtop - floater_top) + 30);
                    }, 30));
                }
            } else {
                $('.floater').css('top', '');
            }
        });

        // Button
        $('#omschrijving + a').on('click', function (e) {
            e.preventDefault();

            $('#omschrijving').addClass('show');
            $(this).hide();
        });

        // Lightbox
        if ($('.lightbox').length > 0) {
            $('.lightbox').magnificPopup({
                type: 'image',
                gallery: {
                    enabled: true
                }
            });
        }

        initObjectPano();

        // Gallery icon
        $('.icon-zoom-in').on('click', function (e) {
            e.preventDefault();
            $('.lightbox').first().click();
        });
    }
}

function initObjectPano() {
    $('[id^=pano-]').each(function (index) {
        id = $(this).attr('data-id');
        data = {
            js: '/js/pano/pano.js',
            swf: '/js/pano/pano.swf',
            // xml: window.location.origin + window.location.pathname + '/pano/' + id + '.xml',
            target: 'pano-' + id,
            html5: 'prefer',
            passQueryParameters: true
        };
        embedpano(data);
    });
}

function initScroll() {
    if ($('.icon-scroll').length) {
        $('.icon-scroll').on('click', function (e) {
            e.preventDefault();
            $('html,body').stop().animate({
                scrollTop: $('#homepage').offset().top - 70
            }, 300, 'linear');
        });
    }
}

function initTimeline() {
    if ($('#overons').length) {
        $('.timeline .line').each(function () {
            var height = $(this).find('.text span p').outerHeight();
            $(this).find('.text span').css('height', height + 40);

            if ($(window).width() >= 1024) {
                $(this).find('.text').css('height', $(this).outerHeight());
                $(this).find('.image').css('height', $(this).outerHeight());
            } else {
                $(this).find('.text').css('height', height + 40);
                $(this).find('.image').css('height', '120px');
            }
        });
    }
}

function initContact() {
    if ($('#contact').length > 0) {
        var latlng = new google.maps.LatLng($('#maps').data('lat'), $('#maps').data('lng'));

        map = new google.maps.Map(document.getElementById('maps'), {
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: latlng,
            scrollwheel: false
        });

        var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: '/img/icon-map.png'
        });
    }
}

function leftPad(number, targetLength) {
    var output = number + '';
    while (output.length < targetLength) {
        output = '0' + output;
    }
    return output;
}

function initTimers() {
    $('.timer').each(function () {
        initTimer($(this));
    });
 }

function initTimer($timer) {
    var countDownDate = new Date(parseInt($timer.data('to')) * 1000);

    var now = new Date().getTime();
    var distance = countDownDate - now;
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    var dagen = '';
    if (days > 1) {
        dagen = days + ' dagen '
    } else if (days == 0) {
        dagen = '';
    } else {
        dagen = days + ' dag ';
    }

    $timer.html('<i class="icon icon-clock-o"></i> ' + dagen + leftPad(hours, 2) + ':' + leftPad(minutes, 2) + ':' + leftPad(seconds, 2));

    if (distance < 0) {
        clearInterval(x);
        $timer.parent().text('ONLINE BIEDING GESLOTEN');
    }

    var x = setInterval(function () {
        var now = new Date().getTime();
        var distance = countDownDate - now;

        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        var dagen = '';
        if (days > 1) {
            dagen = days + ' dagen '
        } else if (days == 0) {
            dagen = '';
        } else {
            dagen = days + ' dag ';
        }

        const html = '<i class="icon icon-clock-o"></i> ' + dagen.toString() + leftPad(hours, 2).toString() + ':' + leftPad(minutes, 2).toString() + ':' + leftPad(seconds, 2).toString();

        $timer.html(html);

        if (distance < 0) {
            clearInterval(x);
            $timer.parent().text('ONLINE BIEDING GESLOTEN');
        }
    }, 1000);
};

function initSearch() {
    if ($('#search').length > 0) {
        var minVal = 0;

        $('#search #prijs_min').change(function () {
            minVal = parseInt($(this).val());

            $('#search #prijs_max option').each(function () {
                $(this).removeAttr('disabled');

                if (parseInt($(this).val()) <= minVal) {
                    $(this).attr('disabled', 'disabled');
                }
            });
        });
    }
}

function initProducts() {
    if ($('.payment_methods').length > 0) {
        $('.payment_methods .method').click(function (e) {
            $('.payment_methods .method').removeClass('active');
            $(this).addClass('active');
            $('input[name=payment_method]').val($(this).data('description'));
        });

        if ($('input[name=payment_method]').val()) {
            $('.payment_methods .method[data-description=' + $('input[name=payment_method]').val() + ']').click();
        }
    }

    if ($('.products').length > 0) {
        var total = 0;

        $('.products .product').each(function () {
            if ($(this).find('input').is(':checked')) {
                total = total + parseFloat($(this).find('input').data('amount'))

                $('.products .total span').html('&euro; ' + total.toFixed(2).toString().replace('.', ','));
            }

            $(this).find('input').change(function () {
                if ($(this).is(':checked')) {
                    total = total + parseFloat($(this).data('amount'))

                    $('.products .total span').html('&euro; ' + total.toFixed(2).toString().replace('.', ','));
                } else {
                    total = total - parseFloat($(this).data('amount'))

                    $('.products .total span').html('&euro; ' + total.toFixed(2).toString().replace('.', ','));
                }
            });
        });
    }
}

function initBid() {
    if ($('#object.auction').length > 0) {
        $('#frmAuction').on('submit', function (e) {
            e.preventDefault();
            $('#frmAuction').prev('.alert').remove();

            var val;
            var input = $('#frmAuction').find('input[name=tmp_amount]');
            if (clean(input.val()) < parseInt(input.data('bid-from'))) {
                $('#frmAuction').before('<div class="alert alert-danger">Bod te laag</div>');
            } else {
                $('#frmAuctionModal').find('input[name=amount]').val(clean(input.val()));
                $('#bid_formatted').html(currency(clean(input.val())));

                if (input.data('fee-type') == 'amount') {
                    val = clean(input.val()) + parseInt(input.data('fee-amount'));
                } else if (input.data('fee-type') == 'percentage') {
                    val = ((clean(input.val()) / 100) * parseFloat(input.data('fee-amount'))) + clean(input.val());
                }


                $('#total_formatted').html(currency(val) + ' k.k.');

                var check_voorwaarden = $('#frmAuctionModal').find('input[name=voorwaarden]');
                check_voorwaarden.on('change', function () {
                    check_voorwaarden.parent('label').removeClass('error');
                });

                $('#frmAuctionModal').on('submit', function (e) {
                    check_voorwaarden.parent('label').removeClass('error');
                    if (!check_voorwaarden.is(':checked')) {
                        check_voorwaarden.parent('label').addClass('error');
                        e.preventDefault();
                    }
                });

                $('#bidModal').modal('show');
            }
        });
    }
}


var clean = function (amount) {
    val = parseInt(amount.replace(/\D/g, ''));
    if (isNaN(val)) return 0;
    return val;
}

var currency = function (amount) {
    decimals = (typeof decimals !== 'undefined' ? decimals : 0);
    return '&euro; ' + number_format(amount, 0, ',', '.');
}

var number_format = function (number, decimals, dec_point, thousands_sep) {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

if ($('#register').length > 0) {
    var timeout;
    $('#postal_code, #housenumber').on('keyup, blur', function () {
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            if ($('#postal_code').val() != '' && $('#housenumber').val() != '') {
                getAdressByPostalZip($('#postal_code').val(), $('#housenumber').val())
            }
        }, 400);
    });
}


// Aanroepen van de postcode api
function getAdressByPostalZip(postalCode, houseNumber) {
    postalCode = cleanUpZipcode(postalCode);
    $.ajax({
        url: '/postcode/address/' + postalCode + '/' + houseNumber,
        type: 'GET',
        async: true,
        success: function (result) {
            if (typeof (result.exception) === 'undefined') {
                $('#street').val(result.street);
                $('#city').val(result.city);
            }
        }
    });
};

// Opschonen van de postcode
function cleanUpZipcode(zipcode) {
    var newZipcode = zipcode.trim();
    newZipcode = newZipcode.replace(/ /g, '');
    newZipcode = newZipcode.toUpperCase();
    return newZipcode;
};

function initFindPrice() {
    $('#show p').each(function () {
        $(this).html($(this).html().replace(/(€ ?\d+\.?\d+,?\d{1,2})/gi, function (str) {
            return '<span class="price">' + str + '</span>';
        }));
    });
}
